.product-details {
    max-width: 590px;
    overflow: hidden;
}

.product-details__subline {
    text-decoration: none;
    font-size: 20px;
    font-family: 'Roboto Condensed', sans-serif;
    color: #4c7c8a;
    line-height: 30px;
    margin: 0;
    margin-top: 20px;
    padding: 0;
}

.product-details__image {
    padding-top: 20px;
}

.product-details__image,
.product-details__form {
    max-width: 360px;
}

@media screen and (min-width: 880px) {
    .product-details__image {
        float: left;
    }

    .product-details {
        max-width: 1010px;
    }

    .product-details__description,
    .product-details__order {
        margin-left: 390px;
    }

    .product-details__description {
        margin-top: 20px;
    }
}

@media screen and (min-width: 1430px) {
    .product-details {
        max-width: 1460px;
    }

    .product-details__description {
        width: 620px;
        float: left;
        margin-left: 30px;
    }

    .product-details__order {
        margin-left: auto;
        float: right;
    }
}