@media screen and (max-width: 899px) {
	.sitemap {
		margin-top: 10px;
	}

	.sitemap ul {
		padding: 0;
		list-style: none;
	}

	.sitemap__main-navigation, .sitemap__meta-navigation {
		margin-top: 20px;
	}

	.sitemap ul a {
	    color: #263337;
	    text-transform: uppercase;
	    text-decoration: none;
	    line-height: 25px;
	    font-family: 'Roboto', sans-serif;
	    font-size: 16px;
	    padding: 10px 0;
	}

	.sitemap ul li {
		margin-top: 5px;
		color: #263337;
	}

	.sitemap ul a:hover {
		text-decoration: underline;
	}

	.sitemap__main-navigation ul li {
		padding-left: 20px;
		background-image: url(sitemap/bullet.png);
    	background-repeat: no-repeat;
    	background-position: 10px 4px;
	}

	.sitemap ul ul a {
	    color: #263337;
	    text-transform: none;
	    text-decoration: none;
	    line-height: 20px;
	    font-family: 'Roboto', sans-serif;
	    font-size: 15px;
	}
}

@media screen and (min-width: 900px) {

/* ------------------------------------------------------------
	General Styles
------------------------------------------------------------ */

.sitemap {
	margin: 30px 0 40px 0;
	float: left;
	width: 100%;

    font-size: 16px;
    color: #263337;
    font-family: 'Roboto', sans-serif;
}

.sitemap .a {
	text-decoration: none;
}

.sitemap ol, .sitemap ul {
	list-style: none;
}

/* ------------------------------------------------------------
	NUMBER OF COLUMNS: Adjust .sitemap__main-navigation li to set the number
	of columns required in your site map. The default is
	4 columns (25%). 5 columns would be 20%, 6 columns would
	be 16.6%, etc.
------------------------------------------------------------ */

.sitemap__main-navigation li {
	width:25%;
}

.sitemap__main-navigation li ul li {
	width:100% !important;
}

.sitemap__main-navigation_col1 li { width:99.9%; }
.sitemap__main-navigation_col2 li { width:50.0%; }
.sitemap__main-navigation_col3 li { width:33.3%; }
.sitemap__main-navigation_col4 li { width:25.0%; }
.sitemap__main-navigation_col5 li { width:20.0%; }
.sitemap__main-navigation_col6 li { width:16.6%; }
.sitemap__main-navigation_col7 li { width:14.2%; }
.sitemap__main-navigation_col8 li { width:12.5%; }
.sitemap__main-navigation_col9 li { width:11.1%; }
.sitemap__main-navigation_col10 li { width:10.0%; }


/* ------------------------------------------------------------
	Site Map Styles
------------------------------------------------------------ */

/* --------	Top Level --------- */

.sitemap__main-navigation {
	margin: 0;
	float: left;
	width: 100%;
	padding: 0;
}
.sitemap__main-navigation #home {
	display: block;
	float: none;
	background: url('sitemap/L1-left.png') center bottom no-repeat;
	position: relative;
	z-index: 2;
	padding: 0 0 30px 0;
}

.sitemap__main-navigation li {
	float: left;
	background: url('sitemap/L1-center.png') center top no-repeat;
	padding: 30px 0;
	margin-top: -30px;
}

.sitemap__main-navigation ul {
	padding-left: 0;
}

.sitemap__item_noconnector {
	background: none !important;
}

.sitemap__main-navigation li a {
	margin: 0 20px 0 0;
	display: block;
	text-align: center;
	color: #4c7c8a;
	text-decoration: none;
	background-color: #edf4f7;
	text-transform: uppercase;
	font-weight: bold;
	font-family: 'Roboto Condensed', sans-serif;
    font-size: 16px;
    padding: 10px 5px;
}

.sitemap__main-navigation li a:hover {
	color: #ed8e2d;
}
.sitemap__main-navigation li:last-child {
	background: url('sitemap/L1-right.png') center top no-repeat;
}

/* --------	Second Level --------- */

.sitemap__main-navigation li li {
	width: 100%;
	clear: left;
	margin-top: 0;
	padding: 10px 0 0 0;
	background: url('sitemap/vertical-line.png') center bottom repeat-y;
}
.sitemap__main-navigation li li a {
	background-color: #edf4f7;
	text-transform: none;
	line-height: 20px;
	padding: 10px;
	color: #263337;
	font-weight: normal;
	font-size: 14px;
	font-family: 'Roboto', sans-serif;
	border: 1px solid #edf4f7;
}
.sitemap__main-navigation li li a:hover {
	color: #ed8e2d;
}
.sitemap__main-navigation li li:first-child {
	padding-top: 30px;
}
.sitemap__main-navigation li li:last-child {
	background: url('sitemap/vertical-line.png') center bottom repeat-y;
}
.sitemap__main-navigation li li a:link:before,
.sitemap__main-navigation li li a:visited:before {
	color: #8faf5c;
}

/* --------	Third Level --------- */


.sitemap__main-navigation li li ul {
	width: 100%;
	float: right;
	padding: 9px 0 0 0;
	background: #ffffff url('sitemap/L3-ul-top.png') center top no-repeat;
}
.sitemap__main-navigation li li li {
	background: url('sitemap/L3-center.png') left center no-repeat;
}
.sitemap__main-navigation li li li a {
	font-size: 12px;
	padding: 5px 0;
	width: 80%;
	float: right;
	background-color: #edf4f7;
}

.sitemap__main-navigation li li li:first-child {
	padding: 10px 0 0 0;
	background: url('sitemap/L3-li-top.png') left center no-repeat;
}
.sitemap__main-navigation li li li:last-child {
	background: url('sitemap/L3-bottom.png') left center no-repeat;
}
.sitemap__main-navigation li li li a:link:before,
.sitemap__main-navigation li li li a:visited:before {
	color: #ccae14;
	font-size: 9px;
}


/* ------------------------------------------------------------
	Utility Navigation
------------------------------------------------------------ */

.sitemap__meta-navigation {
	float: right;
	max-width: 50%;
	margin-right: 10px;
	margin-bottom: 0;
}
.sitemap__meta-navigation li {
	float: left;
}
.sitemap__meta-navigation li a {
	margin: 0 10px 0 0;
	padding: 5px 10px;
	display: block;
	font-size: 12px;
	text-align: center;
	text-decoration: none;
	background-color: #edf4f7;
	color: #4c7c8a;
}
.sitemap__meta-navigation li a:hover {
	color: #ed8e2d;
}

.sitemap__meta-navigation li a:link:before,
.sitemap__meta-navigation li a:visited:before {
	color: #ccae14;
	font-size: 9px;
	margin-bottom: 3px;
}
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
	.sitemap__main-navigation li a {
	    font-size: 12px;
	}

	.sitemap__main-navigation li li a {
		font-size: 11px;
		padding: 5px;
		line-height: 16px;
	}
}