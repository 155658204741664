.copy-bulleted-list {
    font-size: 14px;
    line-height: 17px;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    color: #263337;
    margin: 0;
    padding-left: 20px;
}

.copy-bulleted-list__item {
    margin-top: 5px;
}

@media only screen and (min-width: 800px) {
    .copy-bulleted-list {
        font-size: 18px;
        line-height: 130%;
    }
}