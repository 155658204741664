.footer {
    background-color: #d19455;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 78px;
    text-align: center;
}

.footer__navigation {
    margin: auto;
    padding: 0;
    background-image: url('footer/background.jpg');
    background-position: center;
    height: 78px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    background-repeat: no-repeat;
    padding-top: 10px;
    max-width: 1530px;
}

.footer__navitem {
    font-family: 'Roboto Condensed', sans-serif;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
}

.footer__link {
    text-decoration: none;
    color: #fff;
}

.footer__bullet {
    display: inline-block;
    width: 20px;
    height: 8px;
    background-image: url("footer/bullet_2x.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 4px;
}

@media screen and (min-width: 500px) {
    .footer__navigation {
        text-align: center;
    }
}