.master-head {
    margin-top: 49px;
    height: 437px;
}

.master-head__hero {
    background-image: url("master-head/background-phones.jpg");
    background-repeat: no-repeat;
    text-align: left;
    background-position: center;
    height: 437px;
}

.master-head__title {
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    color: #ff8100;
    margin: 0 30px;
    padding-top: 30px;
    max-width: 338px;
}

.master-head__desc {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    color: #fff;
    margin: 0 30px;
    padding-top: 10px;
    font-weight: 400;
    max-width: 500px;
}



@media screen and (min-width: 500px) {
    .master-head {
        background-color: #d19455;
        overflow: visible;
        height: auto;
        text-align: center;
    }

    .master-head__hero {
        /*height: 432px;*/
        height: 255px;
        max-width: 1530px;
        margin-left: auto;
        margin-right: auto;
        background-image: url("master-head/background.jpg");
        background-repeat: no-repeat;
        text-align: left;
        background-position: -139px 0;
    }

    .master-head__intro {
        margin-left: 233px;
        padding-top: 20px;
    }

    .master-head__title {
        max-width: none;
        margin: 0 20px;
    }

    .master-head__desc {
        margin: 0 20px;
    }
}

@media screen and (min-width: 600px) {
    .master-head__intro {
        margin-left: 233px;
        padding-top: 30px;
    }
}

@media screen and (min-width: 1080px) {
    .master-head {
        margin-top: auto;
        height: 255px;
    }

    .master-head__title {
        font-size: 42px;
    }

    .master-head__hero {
        height: 432px;
    }
}

@media screen and (min-width: 1200px) {
    .master-head__title {
        font-size: 60px;
    }

    .master-head__desc {
        font-size: 20px;
        max-width: 800px;
        font-weight: 100;
    }

    .master-head__title {
        padding-top: 10px;
    }
}

@media screen and (min-width: 1400px) {
    .master-head__hero {
        background-position: center;
    }

    .master-head__intro {
        margin-left: 342px;
    }
}