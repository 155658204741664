.copy {
    font-size: 14px;
    line-height: 17px;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    color: #263337;
}

@media only screen and (min-width: 800px) {
    .copy {
        font-size: 18px;
        line-height: 130%;
        font-family: 'Roboto', sans-serif;
        font-weight: 100;
        color: #263337;
    }
}