.main-introduction {
    margin: 0 -30px 30px -30px;
    padding: 30px;
    background: #e5f1f5;
    box-sizing: border-box;
}

.main-introduction__link {
    text-decoration: none;
}

.main-introduction__copy {
    max-width: 750px;
}

@media only screen and (min-width: 480px) {
    .main-introduction {
        /*
        background: -moz-linear-gradient(left,  #e5f1f5 0%, #ffffff 100%);
        background: -webkit-gradient(linear, left top, right top, color-stop(0%,#e5f1f5), color-stop(100%,#ffffff));
        background: -webkit-linear-gradient(left,  #e5f1f5 0%,#ffffff 100%);
        background: -o-linear-gradient(left,  #e5f1f5 0%,#ffffff 100%);
        background: -ms-linear-gradient(left,  #e5f1f5 0%,#ffffff 100%);
        background: linear-gradient(to right,  #e5f1f5 0%,#ffffff 100%);
        */

        background: -moz-linear-gradient(left,  rgba(229,241,245,1) 0%, rgba(229,241,245,0) 100%);
        background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(229,241,245,1)), color-stop(100%,rgba(229,241,245,0)));
        background: -webkit-linear-gradient(left,  rgba(229,241,245,1) 0%,rgba(229,241,245,0) 100%);
        background: -o-linear-gradient(left,  rgba(229,241,245,1) 0%,rgba(229,241,245,0) 100%);
        background: -ms-linear-gradient(left,  rgba(229,241,245,1) 0%,rgba(229,241,245,0) 100%);
        background: linear-gradient(to right,  rgba(229,241,245,1) 0%,rgba(229,241,245,0) 100%);

    }
}

@media only screen and (min-width: 1580px) {
    .main-introduction {
        margin: 0 0 30px 0;
        max-width: none;
    }
}
