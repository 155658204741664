.product-listing {
    padding-top: 68px;
}

.product-listing__headline {
    color: #ed8e2d;
    line-height: 40px;
    background-color: #edf4f7;
}

.product-listing__headline-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    font-size: 24px;
}

.product-listing__content,
.product-listing__headline-text {
    padding: 0 30px;
    max-width: 700px;
    box-sizing: border-box;
    margin: 0 auto;
}

@media screen and (min-width: 1024px) {
    .product-listing__content,
    .product-listing__headline-text {
        max-width: 1580px;
    }
}