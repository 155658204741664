.home-section {
    padding-bottom: 40px;
}

.home-section__icon,
.home-section__title {
    text-align: center;
}

.home-section__list {
    max-width: 750px;
}

@media only screen and (min-width: 800px) {
    .home-section__icon {
        text-align: left;
        display: inline-block;
        vertical-align: middle;
    }

    .home-section__title {
        display: inline-block;
        vertical-align: middle;
        padding-left: 30px;
    }
}

@media only screen and (min-width: 1024px) {
    .home-section__list {
        max-width: none;
    }
}