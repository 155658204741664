.subline {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 20px;
    line-height: 23.6px;
    color: #4c7c8a;
    margin-top: 1em;
    margin-bottom: 0;
}

@media only screen and (min-width: 800px) {
    .subline {
        font-size: 24px;
        line-height: 130%;
    }
}