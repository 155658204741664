.logo {
    display: block;
    margin: auto;
    width: 122px;
}

.logo__image {
    width: 122px;
    height: 204px;
}

.logo_subpage {
    width: 260px;
    height: 61px;
    margin-top: 79px;
}

@media screen and (min-width: 500px) {
    .logo {
        margin-left: 80px;
        width: 153px;
        height: 255px;
        float: left;
    }

    .logo__image {
        width: 153px;
        height: 255px;
    }

    .logo_subpage {
        width: 260px;
        height: 61px;
        margin-top: 79px;
        margin-left: auto;
        float: none;
    }
}

@media screen and (min-width: 1080px) {
    .logo_subpage {
        display: none;
    }
}

@media screen and (min-width: 1400px) {
    .logo {
        margin-left: 189px;
    }
}