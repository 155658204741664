.article-teaser {
    width: 100%;
    padding-top: 30px;
    max-width: 620px;
    margin: auto;
}

.article-teaser__image {
    display: block;
    margin-bottom: 20px;
}

.article-teaser__title {
    text-decoration: none;
}

.article-teaser__read-more-link {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 24px;
    line-height: 26px;
    color: #ed8e2d;
    text-decoration: none;
}

.article-teaser__read-more-icon {
    width: 14px;
    height: 27px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;

    background-image: url('article-teaser/right-arrow_2x.png');
    background-size: 14px;
}