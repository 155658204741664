.address {
    font-size: 14px;
    line-height: 18px;
    font-family: 'Roboto', sans-serif;
    color: #263337;
    margin-top: 15px;
}

@media only screen and (min-width: 800px) {
    .address {
        font-size: 18px;
        line-height: 130%;
    }
}