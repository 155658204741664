.ordering-form__headline {
    padding-top: 20px;
}

.ordering-form__product-name {
    text-decoration: none;
    font-size: 20px;
    font-family: 'Roboto Condensed', sans-serif;
    color: #4c7c8a;
    line-height: 30px;
    margin: 0;
    margin-top: 20px;
    padding: 0;
}