.article-teaser-mini {
    margin-top: 30px;
}

.article-teaser-mini__link {
    color: #ed8e2d;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    display: block;
}

.article-teaser-mini__excerpt {
    margin-top: 10px;
}

.article-teaser-mini {
    max-width: 490px;
}

@media only screen and (min-width: 800px) {

    .article-teaser-mini {
        max-width: none;
    }
}