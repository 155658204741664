.product-summary {
    margin-top: 30px;
    overflow: hidden;
}

.product-summary__headline {
    font-size: 24px;
    font-family: 'Roboto Condensed', sans-serif;
    color: #4c7c8a;
    line-height: 30px;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    text-decoration: none;
}

.product-summary__title-link {
    text-decoration: none;
}

.product-summary__image {
    max-width: 130px;
    text-align: center;
    margin: 0 auto;
}

.product-summary__link {
    color: #ed8e2d;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    display: block;
    margin-top: 10px;
}

@media screen and (min-width: 500px) {
    .product-summary__image {
        float: left;
        left: 30px;
        top: 0;
    }

    .product-summary__headline,
    .product-summary__description,
    .product-summary__link {
        margin-left: 160px;
    }

}