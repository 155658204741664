.headline-2 {
    font-size: 30px;
    color: #ed8e2d;
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    margin-bottom: 0;
    margin-top: 0;
    word-wrap: break-word;
}

@media only screen and (min-width: 480px) {
    .headline-2 {
        font-size: 42px;
    }
}