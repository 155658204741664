html {
    position: relative;
    min-height: 100%;
}

.default-layout {
    background-color: #fff;
    padding: 0;
    margin: 0 0 146px;
}

.default-layout__content-area {
    padding: 0 30px 0 30px;
    max-width: 1590px;
    box-sizing: border-box;
    margin: 0 auto;
    overflow: hidden;
}