.form {

}

.form__group {
    display: block;
    margin-top: 16px;
    position: relative;
}

.form__label {
    font-size: 14px;
    line-height: 17px;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    color: #263337;
    display: block;
}

.form__text-input {
    width: 100%;
    box-sizing: border-box;
}

.form__textarea-input {
    width: 100%;
    box-sizing: border-box;
    height: 150px;
}

.form__note,
.form__textarea-input,
.form__text-input {
    font-size: 14px;
    line-height: 17px;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    color: #263337;
}

.form__button {
    background-color: #ee4e0f;
    color: #ffffff;
    font-size: 20px;
    border: 0;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: bold;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}


.form__response {
    font-size: 16px;
    line-height: 17px;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    color: #ee4e0f;
    margin-top: 16px;
    padding: 0;
    display: none;
}

.form__validation-message {
    position: absolute;
    bottom: 25px;
    right: 0;
    font-size: 14px;
    line-height: 17px;
    font-family: 'Roboto', sans-serif;
    background-color: #4c7c8a;
    color: #fff;
    padding: 3px 5px;
}

.form__validation-message:after{
    border: solid;
    border-color: #4c7c8a transparent;
    border-width: 6px 6px 0 6px;
    bottom: -5px;
    content: "";
    left: 30%;
    position: absolute;
    z-index: 99;
}

.form__validation-message {
    display: none;
}