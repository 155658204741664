@charset "UTF-8";
/*------------------------------------*\
 *    $CSSWIZARDRY-GRIDS
 *\*------------------------------------ */
/**
 * CONTENTS
 * INTRODUCTION.........How the grid system works.
 * VARIABLES............Your settings.
 * MIXINS...............Library mixins.
 * GRID SETUP...........Build the grid structure.
 * WIDTHS...............Build our responsive widths around our breakpoints.
 * PUSH.................Push classes.
 * PULL.................Pull classes. */
/*------------------------------------*\
 *    $INTRODUCTION
 *\*------------------------------------ */
/**
 * csswizardry grids provides you with widths to suit a number of breakpoints
 * designed around devices of a size you specify. Out of the box, csswizardry
 * grids caters to the following types of device:
 *
 * palm     --  palm-based devices, like phones and small tablets
 * lap      --  lap-based devices, like iPads or laptops
 * portable --  all of the above
 * desk     --  stationary devices, like desktop computers
 * regular  --  any/all types of device
 *
 * These namespaces are then used in the library to give you the ability to
 * manipulate your layouts based around them, for example:
 *
 * <div class="grid__item  one-whole  lap--one-half  desk--one-third">
 *
 * This would give you a grid item which is 100% width unless it is on a lap
 * device, at which point it become 50% wide, or it is on a desktop device, at
 * which point it becomes 33.333% width.
 *
 * csswizardry grids also has push and pull classes which allow you to nudge
 * grid items left and right by a defined amount. These follow the same naming
 * convention as above, but are prepended by either `push--` or `pull--`, for
 * example:
 *
 * `class="grid__item  one-half  push--one-half"`
 *
 * This would give you a grid item which is 50% width and pushed over to the
 * right by 50%.
 *
 * All classes in csswizardry grids follow this patten, so you should fairly
 * quickly be able to piece together any combinations you can imagine, for
 * example:
 *
 * `class="grid__item  one-whole  lap--one-half  desk--one-third  push--desk--one-third"`
 *
 * `class="grid__item  one-quarter  palm--one-half  push--palm--one-half"`
 *
 * `class="grid__item  palm--one-third  desk--five-twelfths"` */
/*------------------------------------*\
 *    $VARIABLES
 *\*------------------------------------ */
/**
 * If you are building a non-responsive site but would still like to use
 * csswizardry-grids, set this to ‘false’: */
/**
 * Is this build mobile first? Setting to ‘true’ means that all grids will be
 * 100% width if you do not apply a more specific class to them. */
/**
 * Set the spacing between your grid items. */
/**
 * Would you like Sass’ silent classes, or regular CSS classes? */
/**
 * Would you like push and pull classes enabled? */
/**
 * Using `inline-block` means that the grid items need their whitespace removing
 * in order for them to work correctly. Set the following to true if you are
 * going to achieve this by manually removing/commenting out any whitespace in
 * your HTML yourself.
 *
 * Setting this to false invokes a hack which cannot always be guaranteed,
 * please see the following for more detail:
 *
 * github.com/csswizardry/csswizardry-grids/commit/744d4b23c9d2b77d605b5991e54a397df72e0688
 * github.com/csswizardry/inuit.css/issues/170#issuecomment-14859371 */
/**
 * Define your breakpoints. The first value is the prefix that shall be used for
 * your classes (e.g. `.palm--one-half`), the second value is the media query
 * that the breakpoint fires at. */
/**
 * Define which namespaced breakpoints you would like to generate for each of
 * widths, push and pull. This is handy if you only need pull on, say, desk, or
 * you only need a new width breakpoint at mobile sizes. It allows you to only
 * compile as much CSS as you need. All are turned on by default, but you can
 * add and remove breakpoints at will.
 *
 * Push and pull shall only be used if `$push` and/or `$pull` and `$responsive`
 * have been set to ‘true’. */
/**
 * You do not need to edit anything from this line onward; csswizardry-grids is
 * good to go. Happy griddin’! */
/*------------------------------------*\
 *    $MIXINS
 *\*------------------------------------ */
/**
 * These mixins are for the library to use only, you should not need to modify
 * them at all.
 *
 * Enclose a block of code with a media query as named in `$breakpoints`. */
/**
 * Drop relative positioning into silent classes which can’t take advantage of
 * the `[class*="push--"]` and `[class*="pull--"]` selectors. */
/*------------------------------------*\
 *    $GRID SETUP
 *\*------------------------------------ */
/**
 * 1. Allow the grid system to be used on lists.
 * 2. Remove any margins and paddings that might affect the grid system.
 * 3. Apply a negative `margin-left` to negate the columns’ gutters. */
.grid {
  list-style: none;
  /* [1] */
  margin: 0;
  /* [2] */
  padding: 0;
  /* [2] */
  margin-left: -30px;
  /* [3] */
  letter-spacing: -0.31em;
}

/* Opera hack */
.opera:-o-prefocus,
.grid {
  word-spacing: -0.43em;
}

/**
 * 1. Cause columns to stack side-by-side.
 * 2. Space columns apart.
 * 3. Align columns to the tops of each other.
 * 4. Full-width unless told to behave otherwise.
 * 5. Required to combine fluid widths and fixed gutters. */
.grid__item {
  display: inline-block;
  /* [1] */
  padding-left: 30px;
  /* [2] */
  vertical-align: top;
  /* [3] */
  width: 100%;
  /* [4] */
  -webkit-box-sizing: border-box;
  /* [5] */
  -moz-box-sizing: border-box;
  /* [5] */
  box-sizing: border-box;
  /* [5] */
  letter-spacing: normal;
  word-spacing: normal;
}

/**
 * Reversed grids allow you to structure your source in the opposite order to
 * how your rendered layout will appear. Extends `.grid`. */
.grid_rev {
  direction: rtl;
  text-align: left;
}
.grid_rev > .grid__item {
  direction: ltr;
  text-align: left;
}

/**
 * Gutterless grids have all the properties of regular grids, minus any spacing.
 * Extends `.grid`. */
.grid_full {
  margin-left: 0;
}
.grid_full > .grid__item {
  padding-left: 0;
}

/**
 * Align the entire grid to the right. Extends `.grid`. */
.grid_right {
  text-align: right;
}
.grid_right > .grid__item {
  text-align: left;
}

/**
 * Centered grids align grid items centrally without needing to use push or pull
 * classes. Extends `.grid`. */
.grid_center {
  text-align: center;
}
.grid_center > .grid__item {
  text-align: left;
}

/**
 * Align grid cells vertically (`.grid--middle` or `.grid--bottom`). Extends
 * `.grid`. */
.grid_middle > .grid__item {
  vertical-align: middle;
}

.grid_bottom > .grid__item {
  vertical-align: bottom;
}

/**
 * Create grids with narrower gutters. Extends `.grid`. */
.grid_narrow {
  margin-left: -15px;
}
.grid_narrow > .grid__item {
  padding-left: 15px;
}

/**
 * Create grids with wider gutters. Extends `.grid`. */
.grid_wide {
  margin-left: -60px;
}
.grid_wide > .grid__item {
  padding-left: 60px;
}

/*------------------------------------*\
 *    $WIDTHS
 *\*------------------------------------ */
/**
 * Create our width classes, prefixed by the specified namespace. */
/**
 * Our regular, non-responsive width classes. */
/**
 * Whole */
.grid__one-whole {
  width: 100%;
}

/**
 * Halves */
.grid__one-half, .grid__two-quarters, .grid__three-sixths, .grid__four-eighths, .grid__five-tenths, .grid__six-twelfths {
  width: 50%;
}

/**
 * Thirds */
.grid__one-third, .grid__two-sixths, .grid__four-twelfths {
  width: 33.333%;
}

.grid__two-thirds, .grid__four-sixths, .grid__eight-twelfths {
  width: 66.666%;
}

/**
 * Quarters */
.grid__one-quarter, .grid__two-eighths, .grid__three-twelfths {
  width: 25%;
}

.grid__three-quarters, .grid__six-eighths, .grid__nine-twelfths {
  width: 75%;
}

/**
 * Fifths */
.grid__one-fifth, .grid__two-tenths {
  width: 20%;
}

.grid__two-fifths, .grid__four-tenths {
  width: 40%;
}

.grid__three-fifths, .grid__six-tenths {
  width: 60%;
}

.grid__four-fifths, .grid__eight-tenths {
  width: 80%;
}

/**
 * Sixths */
.grid__one-sixth, .grid__two-twelfths {
  width: 16.666%;
}

.grid__five-sixths, .grid__ten-twelfths {
  width: 83.333%;
}

/**
 * Eighths */
.grid__one-eighth {
  width: 12.5%;
}

.grid__three-eighths {
  width: 37.5%;
}

.grid__five-eighths {
  width: 62.5%;
}

.grid__seven-eighths {
  width: 87.5%;
}

/**
 * Tenths */
.grid__one-tenth {
  width: 10%;
}

.grid__three-tenths {
  width: 30%;
}

.grid__seven-tenths {
  width: 70%;
}

.grid__nine-tenths {
  width: 90%;
}

/**
 * Twelfths */
.grid__one-twelfth {
  width: 8.333%;
}

.grid__five-twelfths {
  width: 41.666%;
}

.grid__seven-twelfths {
  width: 58.333%;
}

.grid__eleven-twelfths {
  width: 91.666%;
}

/**
 * Our responsive classes, if we have enabled them. */
@media only screen and (min-width: 481px) and (max-width: 800px) {
  /**
   * Whole */
  .grid__palm-one-whole {
    width: 100%;
  }

  /**
   * Halves */
  .grid__palm-one-half, .grid__palm-two-quarters, .grid__palm-three-sixths, .grid__palm-four-eighths, .grid__palm-five-tenths, .grid__palm-six-twelfths {
    width: 50%;
  }

  /**
   * Thirds */
  .grid__palm-one-third, .grid__palm-two-sixths, .grid__palm-four-twelfths {
    width: 33.333%;
  }

  .grid__palm-two-thirds, .grid__palm-four-sixths, .grid__palm-eight-twelfths {
    width: 66.666%;
  }

  /**
   * Quarters */
  .grid__palm-one-quarter, .grid__palm-two-eighths, .grid__palm-three-twelfths {
    width: 25%;
  }

  .grid__palm-three-quarters, .grid__palm-six-eighths, .grid__palm-nine-twelfths {
    width: 75%;
  }

  /**
   * Fifths */
  .grid__palm-one-fifth, .grid__palm-two-tenths {
    width: 20%;
  }

  .grid__palm-two-fifths, .grid__palm-four-tenths {
    width: 40%;
  }

  .grid__palm-three-fifths, .grid__palm-six-tenths {
    width: 60%;
  }

  .grid__palm-four-fifths, .grid__palm-eight-tenths {
    width: 80%;
  }

  /**
   * Sixths */
  .grid__palm-one-sixth, .grid__palm-two-twelfths {
    width: 16.666%;
  }

  .grid__palm-five-sixths, .grid__palm-ten-twelfths {
    width: 83.333%;
  }

  /**
   * Eighths */
  .grid__palm-one-eighth {
    width: 12.5%;
  }

  .grid__palm-three-eighths {
    width: 37.5%;
  }

  .grid__palm-five-eighths {
    width: 62.5%;
  }

  .grid__palm-seven-eighths {
    width: 87.5%;
  }

  /**
   * Tenths */
  .grid__palm-one-tenth {
    width: 10%;
  }

  .grid__palm-three-tenths {
    width: 30%;
  }

  .grid__palm-seven-tenths {
    width: 70%;
  }

  .grid__palm-nine-tenths {
    width: 90%;
  }

  /**
   * Twelfths */
  .grid__palm-one-twelfth {
    width: 8.333%;
  }

  .grid__palm-five-twelfths {
    width: 41.666%;
  }

  .grid__palm-seven-twelfths {
    width: 58.333%;
  }

  .grid__palm-eleven-twelfths {
    width: 91.666%;
  }
}
@media only screen and (min-width: 801px) and (max-width: 1023px) {
  /**
   * Whole */
  .grid__lap-one-whole {
    width: 100%;
  }

  /**
   * Halves */
  .grid__lap-one-half, .grid__lap-two-quarters, .grid__lap-three-sixths, .grid__lap-four-eighths, .grid__lap-five-tenths, .grid__lap-six-twelfths {
    width: 50%;
  }

  /**
   * Thirds */
  .grid__lap-one-third, .grid__lap-two-sixths, .grid__lap-four-twelfths {
    width: 33.333%;
  }

  .grid__lap-two-thirds, .grid__lap-four-sixths, .grid__lap-eight-twelfths {
    width: 66.666%;
  }

  /**
   * Quarters */
  .grid__lap-one-quarter, .grid__lap-two-eighths, .grid__lap-three-twelfths {
    width: 25%;
  }

  .grid__lap-three-quarters, .grid__lap-six-eighths, .grid__lap-nine-twelfths {
    width: 75%;
  }

  /**
   * Fifths */
  .grid__lap-one-fifth, .grid__lap-two-tenths {
    width: 20%;
  }

  .grid__lap-two-fifths, .grid__lap-four-tenths {
    width: 40%;
  }

  .grid__lap-three-fifths, .grid__lap-six-tenths {
    width: 60%;
  }

  .grid__lap-four-fifths, .grid__lap-eight-tenths {
    width: 80%;
  }

  /**
   * Sixths */
  .grid__lap-one-sixth, .grid__lap-two-twelfths {
    width: 16.666%;
  }

  .grid__lap-five-sixths, .grid__lap-ten-twelfths {
    width: 83.333%;
  }

  /**
   * Eighths */
  .grid__lap-one-eighth {
    width: 12.5%;
  }

  .grid__lap-three-eighths {
    width: 37.5%;
  }

  .grid__lap-five-eighths {
    width: 62.5%;
  }

  .grid__lap-seven-eighths {
    width: 87.5%;
  }

  /**
   * Tenths */
  .grid__lap-one-tenth {
    width: 10%;
  }

  .grid__lap-three-tenths {
    width: 30%;
  }

  .grid__lap-seven-tenths {
    width: 70%;
  }

  .grid__lap-nine-tenths {
    width: 90%;
  }

  /**
   * Twelfths */
  .grid__lap-one-twelfth {
    width: 8.333%;
  }

  .grid__lap-five-twelfths {
    width: 41.666%;
  }

  .grid__lap-seven-twelfths {
    width: 58.333%;
  }

  .grid__lap-eleven-twelfths {
    width: 91.666%;
  }
}
@media only screen and (max-width: 1023px) {
  /**
   * Whole */
  .grid__portable-one-whole {
    width: 100%;
  }

  /**
   * Halves */
  .grid__portable-one-half, .grid__portable-two-quarters, .grid__portable-three-sixths, .grid__portable-four-eighths, .grid__portable-five-tenths, .grid__portable-six-twelfths {
    width: 50%;
  }

  /**
   * Thirds */
  .grid__portable-one-third, .grid__portable-two-sixths, .grid__portable-four-twelfths {
    width: 33.333%;
  }

  .grid__portable-two-thirds, .grid__portable-four-sixths, .grid__portable-eight-twelfths {
    width: 66.666%;
  }

  /**
   * Quarters */
  .grid__portable-one-quarter, .grid__portable-two-eighths, .grid__portable-three-twelfths {
    width: 25%;
  }

  .grid__portable-three-quarters, .grid__portable-six-eighths, .grid__portable-nine-twelfths {
    width: 75%;
  }

  /**
   * Fifths */
  .grid__portable-one-fifth, .grid__portable-two-tenths {
    width: 20%;
  }

  .grid__portable-two-fifths, .grid__portable-four-tenths {
    width: 40%;
  }

  .grid__portable-three-fifths, .grid__portable-six-tenths {
    width: 60%;
  }

  .grid__portable-four-fifths, .grid__portable-eight-tenths {
    width: 80%;
  }

  /**
   * Sixths */
  .grid__portable-one-sixth, .grid__portable-two-twelfths {
    width: 16.666%;
  }

  .grid__portable-five-sixths, .grid__portable-ten-twelfths {
    width: 83.333%;
  }

  /**
   * Eighths */
  .grid__portable-one-eighth {
    width: 12.5%;
  }

  .grid__portable-three-eighths {
    width: 37.5%;
  }

  .grid__portable-five-eighths {
    width: 62.5%;
  }

  .grid__portable-seven-eighths {
    width: 87.5%;
  }

  /**
   * Tenths */
  .grid__portable-one-tenth {
    width: 10%;
  }

  .grid__portable-three-tenths {
    width: 30%;
  }

  .grid__portable-seven-tenths {
    width: 70%;
  }

  .grid__portable-nine-tenths {
    width: 90%;
  }

  /**
   * Twelfths */
  .grid__portable-one-twelfth {
    width: 8.333%;
  }

  .grid__portable-five-twelfths {
    width: 41.666%;
  }

  .grid__portable-seven-twelfths {
    width: 58.333%;
  }

  .grid__portable-eleven-twelfths {
    width: 91.666%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1400px) {
  /**
   * Whole */
  .grid__desk-one-whole {
    width: 100%;
  }

  /**
   * Halves */
  .grid__desk-one-half, .grid__desk-two-quarters, .grid__desk-three-sixths, .grid__desk-four-eighths, .grid__desk-five-tenths, .grid__desk-six-twelfths {
    width: 50%;
  }

  /**
   * Thirds */
  .grid__desk-one-third, .grid__desk-two-sixths, .grid__desk-four-twelfths {
    width: 33.333%;
  }

  .grid__desk-two-thirds, .grid__desk-four-sixths, .grid__desk-eight-twelfths {
    width: 66.666%;
  }

  /**
   * Quarters */
  .grid__desk-one-quarter, .grid__desk-two-eighths, .grid__desk-three-twelfths {
    width: 25%;
  }

  .grid__desk-three-quarters, .grid__desk-six-eighths, .grid__desk-nine-twelfths {
    width: 75%;
  }

  /**
   * Fifths */
  .grid__desk-one-fifth, .grid__desk-two-tenths {
    width: 20%;
  }

  .grid__desk-two-fifths, .grid__desk-four-tenths {
    width: 40%;
  }

  .grid__desk-three-fifths, .grid__desk-six-tenths {
    width: 60%;
  }

  .grid__desk-four-fifths, .grid__desk-eight-tenths {
    width: 80%;
  }

  /**
   * Sixths */
  .grid__desk-one-sixth, .grid__desk-two-twelfths {
    width: 16.666%;
  }

  .grid__desk-five-sixths, .grid__desk-ten-twelfths {
    width: 83.333%;
  }

  /**
   * Eighths */
  .grid__desk-one-eighth {
    width: 12.5%;
  }

  .grid__desk-three-eighths {
    width: 37.5%;
  }

  .grid__desk-five-eighths {
    width: 62.5%;
  }

  .grid__desk-seven-eighths {
    width: 87.5%;
  }

  /**
   * Tenths */
  .grid__desk-one-tenth {
    width: 10%;
  }

  .grid__desk-three-tenths {
    width: 30%;
  }

  .grid__desk-seven-tenths {
    width: 70%;
  }

  .grid__desk-nine-tenths {
    width: 90%;
  }

  /**
   * Twelfths */
  .grid__desk-one-twelfth {
    width: 8.333%;
  }

  .grid__desk-five-twelfths {
    width: 41.666%;
  }

  .grid__desk-seven-twelfths {
    width: 58.333%;
  }

  .grid__desk-eleven-twelfths {
    width: 91.666%;
  }
}
@media only screen and (min-width: 1401px) {
  /**
   * Whole */
  .grid__widescreen-one-whole {
    width: 100%;
  }

  /**
   * Halves */
  .grid__widescreen-one-half, .grid__widescreen-two-quarters, .grid__widescreen-three-sixths, .grid__widescreen-four-eighths, .grid__widescreen-five-tenths, .grid__widescreen-six-twelfths {
    width: 50%;
  }

  /**
   * Thirds */
  .grid__widescreen-one-third, .grid__widescreen-two-sixths, .grid__widescreen-four-twelfths {
    width: 33.333%;
  }

  .grid__widescreen-two-thirds, .grid__widescreen-four-sixths, .grid__widescreen-eight-twelfths {
    width: 66.666%;
  }

  /**
   * Quarters */
  .grid__widescreen-one-quarter, .grid__widescreen-two-eighths, .grid__widescreen-three-twelfths {
    width: 25%;
  }

  .grid__widescreen-three-quarters, .grid__widescreen-six-eighths, .grid__widescreen-nine-twelfths {
    width: 75%;
  }

  /**
   * Fifths */
  .grid__widescreen-one-fifth, .grid__widescreen-two-tenths {
    width: 20%;
  }

  .grid__widescreen-two-fifths, .grid__widescreen-four-tenths {
    width: 40%;
  }

  .grid__widescreen-three-fifths, .grid__widescreen-six-tenths {
    width: 60%;
  }

  .grid__widescreen-four-fifths, .grid__widescreen-eight-tenths {
    width: 80%;
  }

  /**
   * Sixths */
  .grid__widescreen-one-sixth, .grid__widescreen-two-twelfths {
    width: 16.666%;
  }

  .grid__widescreen-five-sixths, .grid__widescreen-ten-twelfths {
    width: 83.333%;
  }

  /**
   * Eighths */
  .grid__widescreen-one-eighth {
    width: 12.5%;
  }

  .grid__widescreen-three-eighths {
    width: 37.5%;
  }

  .grid__widescreen-five-eighths {
    width: 62.5%;
  }

  .grid__widescreen-seven-eighths {
    width: 87.5%;
  }

  /**
   * Tenths */
  .grid__widescreen-one-tenth {
    width: 10%;
  }

  .grid__widescreen-three-tenths {
    width: 30%;
  }

  .grid__widescreen-seven-tenths {
    width: 70%;
  }

  .grid__widescreen-nine-tenths {
    width: 90%;
  }

  /**
   * Twelfths */
  .grid__widescreen-one-twelfth {
    width: 8.333%;
  }

  .grid__widescreen-five-twelfths {
    width: 41.666%;
  }

  .grid__widescreen-seven-twelfths {
    width: 58.333%;
  }

  .grid__widescreen-eleven-twelfths {
    width: 91.666%;
  }
}
/*------------------------------------*\
 *    $PUSH
 *\*------------------------------------ */
/**
 * Push classes, to move grid items over to the right by certain amounts. */
/*------------------------------------*\
 *    $PULL
 *\*------------------------------------ */
/**
 * Pull classes, to move grid items back to the left by certain amounts. */
