.company-info {
    text-align: center;
}

.company-info__image {
    display: none;
    width: 360px;
}

.company-info__about-us,
.company-info__services,
.company-info__references,
.company-info__method {
    padding-top: 40px;
    max-width: 620px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

.company-info__reference-list {
    margin: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
    list-style: none;
}

.company-info__reference-wrapper {
    padding-top: 10px;
}

.company-info__reference {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
    color: #4c7c8a;
    font-family: 'Roboto Condensed', sans-serif;
}

@media screen and (min-width: 910px) {
    .company-info {
        text-align: left;
    }

    .company-info__image {
        display: block;
        float: left;
        padding-top: 40px;
    }

    .company-info__about-us {
        margin-left: 390px;
    }

    .company-info__services,
    .company-info__method {
        max-width: 750px;
        margin-left: 0;
    }

    .company-info__references {
        margin-left: 0;
        max-width: none;
    }
}

@media screen and (min-width: 1600px) {
    .company-info__about-us {
        float: left;
        margin-left: 30px;
    }

    .company-info__method {
        float: left;
        max-width: 620px;
        margin-left: 390px;
    }

    .company-info__services {
        width: 490px;
        float: right;
        margin-left: 30px;
    }

    .company-info__references {
        clear: both;
    }
}
