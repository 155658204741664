.table {
    font-size: 14px;
    line-height: 17px;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    color: #263337;
}

.table__cell {
    padding-right: 15px;
}