.category-navigation {
    padding-top: 68px;
}

.category-navigation__list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 10px;
}

.category-navigation__link {
    text-decoration: none;
    font-size: 24px;
    font-family: 'Roboto Condensed', sans-serif;
    color: #4c7c8a;
    line-height: 30px;
}

.category-navigation__title {
    padding-top: 10px;
    text-decoration: none;
    display: block;
}

.category-navigation__image {
    display: block;
    max-width: 500px;
}