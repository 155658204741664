.page-title {
    text-align: center;
    margin-top: 30px;
}

.page-title_padded {
    margin-bottom: 68px;
}

.page-title__headline {
    font-size: 42px;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    color: #ed8e2d;
    display: block;
    text-align: center;
    line-height: 100%;
    padding: 0;
    margin: 0 0 0 0;
    word-wrap: break-word;
}

.page-title__text-wrapper {
    text-align: left;
}

@media only screen and (min-width: 480px) {

}

@media only screen and (min-width: 1080px) {
    .page-title__headline {
        text-align: left;
        display: inline-block;
        padding: 0;
        font-size: 60px;
        vertical-align: bottom;
        margin-left: 20px;
        max-width: 650px;
    }
}

@media screen and (min-width: 1080px) {
    .page-title {
        margin-top: 0;
    }
}

@media only screen and (min-width: 1200px) {
    .page-title__headline {
        max-width: 900px;
    }
}