.article-recommendations {
    background-color: #fcedcf;
    padding: 30px;
    margin-left: -30px;
    margin-right: -30px;
    max-width: 460px;
    box-sizing: border-box;
}

.article-recommendations__title {
    margin-top: 0;
}

.article-recommendations__list {
    padding: 0;
    margin: 0;
    list-style: none;
    padding-top: 10px;
}

.article-recommendations__list-item {
    margin-top: 10px;
}

.article-recommendations__link {
    text-decoration: none;
}

.article-recommendations__button {
    color: #fff;
    font-size: 30px;
    background-color: #ee4e0f;
    display: block;
    text-decoration: none;
    padding: 10px 20px;
    margin-top: 20px;
}

@media only screen and (min-width: 460px) {
    .article-recommendations {
        margin-left: 0;
        margin-right: 0;
        max-width: none;
    }

    .article-recommendations__button {
        max-width: 410px;
        text-align: left;
        box-sizing: border-box;
    }
}