.topnav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 49px;
    overflow: hidden;
    background-color: #fff;
    z-index: 300;
    margin-bottom: -86px;
}

.topnav__container {
    list-style: none;
    margin: 0;
    padding: 0;
}

.topnav__label {
    text-align: center;
    color: #fff;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 49px;
    cursor: pointer;
    background: #172a2f;
}

.topnav__item-addition {
    font-size: 12px;
    display: block;
    text-transform: none;
    line-height: 95%;
}

.topnav__item-link {
    padding-left: 95px;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    color: #4c7c8a;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    padding-top: 15px;
    min-height: 75px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: 18px 2px;
    background-size: 72px;
    border-bottom: 1px solid #4c7c8a;
}

.topnav__item-link:hover,
.topnav__item-link_active {
    color: #ed8e2d;
    background-position: 18px -79px;
}

.topnav__item-link[href="/"] {  background-image: url(topnav/icon-home_2x.png); }
.topnav__item-link[href="aktuell/"] {  background-image: url(topnav/icon-aktuelles_2x.png); }
.topnav__item-link[href="unternehmen/"] {  background-image: url(topnav/icon-unternehmen_2x.png); }
.topnav__item-link[href="gebaeude-saalbeschallung/"] {  background-image: url(topnav/icon-gebaeude-saalbeschallung_2x.png); }
.topnav__item-link[href="kirchenbeschallung/"] {  background-image: url(topnav/icon-kirchenbeschallung_2x.png); }
.topnav__item-link[href="mobille-systeme/"] {  background-image: url(topnav/icon-mobille-systeme_2x.png); }
.topnav__item-link[href="video-kamera-projektion/"] {  background-image: url(topnav/icon-video-kamera-projektion_2x.png); }

.topnav__item_extra {
    height: 52px;
}

.topnav__item_extra .topnav__item-link {
    color: #172a2f;
    min-height: 52px;
    padding-left: 55px
}

.topnav__l2-container {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
}

.topnav__l2-container_open {
    display: block;
}

.topnav__l2-item-link {
    background-color: #ed8e2d;
    font-size: 24px;
    color: #fff;
    border-bottom: 1px solid #fff;
    display: block;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    height: 50px;
    padding-left: 55px;
    padding-top: 10px;
    box-sizing: border-box;
}

.topnav__l2-item-link:hover,
.topnav__l2-item-link_active {
    background-color: #d76d00;
}


@media screen and (min-width: 1080px) {
    .topnav {
        position: inherit;
        text-align: center;
        overflow: hidden;
        height: 237px;
        background-color: transparent;
        background-image: url("topnav/subpage-background.png");
        background-position: center;
        background-repeat: no-repeat;
    }

    .topnav_home {
        background-image: none;
        height: auto;
        background: transparent;
    }

    .topnav__container {
        margin-top: 23px;
        overflow: hidden;
        height: 150px;
    }

    .topnav__label {
        display: none;
    }

    .topnav__item {
        display: inline-block;
        vertical-align: top;
        margin-right: 2%;
    }

    .topnav__item-addition {
        font-size: 9px;
        line-height: 100%;
    }

    .topnav__item-link {
        border: none;
        padding-left: 55px;
        background-position: 0 2px;
        background-size: 53px;
        text-align: left;
        font-size: 12px;
        min-height: 0;
        height: 53px;
        margin: 0;
    }

    .topnav__item-link:hover,
    .topnav__item-link_active {
        background-position: 0 -57px;
    }

    .topnav_home .topnav__item_home {
        display: none;
    }

    .topnav__item_extra {
        display: none;
    }

    .topnav__l2-container {
        display: none;
    }
}

@media screen and (min-width: 1040px) {


}